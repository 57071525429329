import React from "react"

const Oauth = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <p>処理中…</p>
    </div>
  )
}

export default Oauth
